import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import client from "../../api/client";
import { downloadResource } from "../../constants/DownloadResource";
import { useTable, useSortBy, usePagination, Column, useExpanded } from "react-table";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { Button } from "../Button";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { SortableHeader } from "../commom/SortableHeader";
import { InvoicesSearchParams } from "./InvoicesSearchBar";
import { EservicesStatus } from "../../constants/EservicesStatus";
import { Status, StatusVariant } from "../Status";

type searchParams = {
    "filter[number]"?: string,
    "filter[paymentstatus]"?: string,
    "filter[technical_sale]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "sort"?: string
};

type InvoicesTableProps = {
    items: any,
    setParams: React.Dispatch<React.SetStateAction<InvoicesSearchParams>>,
    renderRowSubComponent: any
};

const handleDownloadClick = async (ev: any, id: string) => {
    ev.preventDefault();

    const res = await client.get(`/invoices/${id}/download`, {
        headers: {
            responseType: "blob"
        }
    });

    // mode download
    if (res.data && res.data.content && res.data.name && res.data.type) {
        const filetype = res.data.type;
        const content = res.data.content;
        const filename = res.data.name;

        downloadResource(content, filename, filetype);
    }
};

export const InvoicesTable = ({ renderRowSubComponent, items, setParams }: InvoicesTableProps) => {
    const { user, roles } = useAuthContext();
    const { t } = useTranslation();

    //state
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    // formatter per le date
    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    // table data
    const data = useMemo(() => items, [items]);

    const columns: Array<Column<any>> = useMemo(() => [
        {
            id: "expander",
            Header: "",
            disableSortBy: true,
            className: "border-b p-4",
            Cell: ({ row }: any) =>
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row

                <span
                    {...row.getToggleRowExpandedProps({
                        style: {
                            // We can even use the row.depth property
                            // and paddingLeft to indicate the depth
                            // of the row
                            paddingLeft: `${row.depth * 2}rem`,
                        },
                        tabIndex: 0
                    })}
                >
                    {row.isExpanded ? <HiChevronUp className="text-xl" /> : <HiChevronDown className="text-xl" />}
                </span>
        },
        {
            Header: (props) => {
                return <SortableHeader props={props} title={t("Numero fattura").toString()} />;
            },
            accessor: "number",
            className: "border-b p-4 text-left",
            Cell: props => <span>{props.row.original.number}</span>
        },
        {
            Header: t("Codice cliente").toString(),
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                const customerCode = props.row.original.customer?.code;

                return <span>{customerCode}</span>;
            }
        },
        {
            Header: t("Nome cliente").toString(),
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                const customerName = props.row.original.customer?.name;

                return <span>{customerName}</span>;
            }
        },
        {
            Header: t("Committente").toString(),
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                return <span>{props?.row?.original?.customer?.code === props?.row?.original?.orderingParty?.code
                    ? "-"
                    : props?.row?.original?.orderingParty?.code}
                </span>
            }
        },
        {
            Header: t("Technical Salesman").toString(),
            accessor: "technical_salesman",
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                const customer = props.row.original.customer;

                // return <span>{customer?.ts_code}</span>
                return <span>{customer?.ts_fullname}</span>;
            }
        },
        {
            Header: (props) => {
                return <SortableHeader props={props} title={t("Data fattura").toString()} />;
            },
            accessor: "date",
            className: "border-b p-4 text-left",
            Cell: props => {
                const emittedData = new Date(props.row.original.date);
                const date = props.dateFormat.format(emittedData);
                return <span>{date}</span>;
            }
        },
        {
            Header: t("Metodo di pagamento").toString(),
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: props => {
                const description = props.row.original.paymentMethod?.description;

                return <span>{description ?? "-"}</span>;
            }
        },
        {
            Header: t("Condizioni di pagamento").toString(),
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: props => {
                const description = props.row.original.paymentTerm?.description;

                return <span>{description ?? "-"}</span>;
            }
        },
        {
            Header: t("Importo senza IVA").toString(),
            accessor: "importo_senza_iva",
            disableSortBy: true,
            className: "border-b p-4 text-right",
            Cell: props => {
                const importo = props.row.original.netamount;

                const numberFormat = new Intl.NumberFormat(user?.interface_language_id, { style: "currency", currency: props.row.original.currency_id, minimumFractionDigits: getCurrencyDecimals(props.row.original.currency_id), maximumFractionDigits: getCurrencyDecimals(props.row.original.currency_id) });

                return <span>{importo ? numberFormat.format(importo) : numberFormat.format(0)}</span>;
            }
        },
        {
            Header: t("Importo ivato").toString(),
            accessor: "importo_ivato",
            disableSortBy: true,
            className: "border-b p-4 text-right",
            Cell: props => {
                const importoTotale = props.row.original.grossamount;

                const numberFormat = new Intl.NumberFormat(user?.interface_language_id, { style: "currency", currency: props.row.original.currency_id, minimumFractionDigits: getCurrencyDecimals(props.row.original.currency_id), maximumFractionDigits: getCurrencyDecimals(props.row.original.currency_id) });

                return <span>{importoTotale ? numberFormat.format(importoTotale) : numberFormat.format(0)}</span>;
            }
        },
        {
            Header: t("Stato fattura").toString(),
            accessor: "paymentstatus",
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => {
                // const invoiceStatus = props.row.original.status;
                // const eserviceInvoiceStatus = EservicesStatus.invoice.find((status) => status.status === invoiceStatus);
                // if (invoiceStatus === '4') return <Status variant={Status.variant['invoice_reversed']}>{t(eserviceInvoiceStatus?.description ?? '')}</Status>
                const paymentStatus = props.row.original.paymentstatus;
                const status = EservicesStatus.invoice.find((status) => status.status == paymentStatus);
                const paymentStatusVariant = status?.variant as keyof typeof StatusVariant;

                if (!paymentStatusVariant) return "";

                return <Status variant={Status.variant[paymentStatusVariant]}>{t(status?.description ?? "")}</Status>;
            }
        },
        {
            Header: t("Fattura in Pdf").toString(),
            disableSortBy: true,
            className: "border-b p-4 text-left",
            Cell: (props) => <Button onClick={ev => handleDownloadClick(ev, props.row.original.id)} variant={Button.variant.secondary}>{t("Download")}</Button>
        },
    ], [t, hiddenColumns]);

    // SET HIDDEN COLUMNS
    useEffect(() => {
        if (roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"])) {
            setHiddenColumns(["technical_salesman"]);
        } else {
            setHiddenColumns([]);
        }
    }, [data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        state: { sortBy }
    } = useTable({
        columns,
        data,
        manualSortBy: true,
        disableSortRemove: true,
        initialState: { hiddenColumns: hiddenColumns, pageSize: 10 },
        dateFormat
    }, useSortBy, useExpanded, usePagination);

    // permetto l'ordinamento in tabella delle colonne number (numero fattura) e date (data fattura)
    useEffect(() => {
        if (["number", "date"].includes(sortBy[0]?.id))
            setParams((prevParams: searchParams) => ({ ...prevParams, sort: sortBy[0].desc ? sortBy[0].id : `-${sortBy[0].id}` }));
    }, [sortBy]);

    return (
        <>
            <table {...getTableProps()} className="border w-full text-xs font-light overflow-x-scroll block lg:table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                            {headerGroup.headers.map((column, idx) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            // Use a React.Fragment here so the table markup is still valid
                            <React.Fragment key={row.getRowProps().key}>
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps({ className: (cell.column as any).className })}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length} className="border">
                                            {/*
                                                Inside it, call our renderRowSubComponent function. In reality,
                                                you could pass whatever you want as props to
                                                a component like this, including the entire
                                                table instance. But for this example, we'll just
                                                pass the row
                                            */}
                                            {renderRowSubComponent({ row, rowProps: row.getRowProps(), visibleColumns })}
                                        </td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
