import RothoNotes from "../cart/RothoNotes";
import Select from "react-select";
import ClientReference from "../cart/ClientReference";
import { useTranslation } from "react-i18next";
import { Modal } from "../commom/Modal";
import { Button } from "../Button";
import { useSelectStyles } from "../../hooks/useSelectStyles";
import { useState } from "react";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

type OrderConfirmationProps = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    handleConfirmClick: (orderClassification: string | null) => Promise<void>,
    clientReference: string
};

const options = [
    {
        value: 'T',
        label: 'ESERVICE EMPLOYEE FROM CUSTOMER'
    },
    {
        value: 'U',
        label: 'ESERVICE EMPLOYEE FROM SALES FORCE'
    }
];

const OrderConfirmation = ({ open, setOpen, handleConfirmClick, clientReference }: OrderConfirmationProps) => {
    const { t } = useTranslation();
    const { can } = useAuthContext();
    const { CustomStyles } = useSelectStyles();

    // state
    const [orderClassification, setOrderClassification] = useState<string | null>(null);
    const [classificationError, setClassificationError] = useState<string | null>(null);

    const handleOrderClassificationSelect = (inputValue: any) => {
        if (inputValue?.value) {
            setClassificationError(null);
            setOrderClassification(inputValue?.value);
        } else {
            setOrderClassification(null);
        }
    }

    const submit = () => {
        if (can(['can_set_order_classification']) && !orderClassification) {
            setClassificationError('- questo campo è obbligatorio');
        } else {
            handleConfirmClick(orderClassification)
        }
    }

    return (
        <Modal title={t("Conferma ordine")} open={open} onClose={setOpen}>
            <div className="space-y-4">
                <ClientReference clientReference={clientReference} />

                <RothoNotes />
                {
                    can(['can_set_order_classification']) &&
                    <div>
                        <label htmlFor="customs_bill">{t("Order classification")}</label>
                        <Select
                            id="order_classification"
                            options={options}
                            onChange={handleOrderClassificationSelect}
                            styles={CustomStyles}
                            placeholder={t("seleziona la classificazione")}
                            isClearable
                            escapeClearsValue
                        />
                        <p className="text-sm text-rose-500 mt-1">{classificationError}</p>
                    </div>
                }

                <p>{t("Sei sicuro di confermare il tuo ordine?")}</p>

                <div className="text-right space-x-2 mt-4">
                    <Button variant={Button.variant.text} onClick={() => setOpen(false)}>{t("Annulla")}</Button>
                    <Button variant={Button.variant.primary} onClick={submit}>{t("Procedi")}</Button>
                </div>
            </div>
        </Modal>
    );
};

export default OrderConfirmation;
