import { useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import client from "../client";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

type Props = {
    customer_code: any;
    item_id: any;
};

const useDebouncedShippings = ({ customer_code, item_id }: Props) => {
    const { user } = useAuthContext();
    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    // GET SHIPPINGS
    const getShippings = useCallback(async (search: string) => {
        const params = {
            "filter[slipnumber]": search,
            "filter[customer_code]": customer_code,
            "filter[item_id]": item_id,
            pageSize: 1000
        };

        const res = await client.get("/shipping/shippings", { params: params });

        return res.data.data.map((shipping: any) => {
            return { ...shipping, label: `${shipping.slipnumber} - ${dateFormat.format(new Date(shipping.date))}` };
        });
    }, [customer_code, item_id]);

    const loadShippingsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getShippings(value).then((options) => callback(options));
        }, 1000);
    }, [getShippings]);

    return { loadShippingsOptions };
};

export default useDebouncedShippings;
