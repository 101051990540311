import client from "../api/client";
import { Button } from "../components/Button"
import { useTranslation } from "react-i18next"
import { downloadResource } from "../constants/DownloadResource";
import { Loader } from "../components/Loader";
import { useEffect, useState } from "react";
import { useSelectStyles } from "../hooks/useSelectStyles";
import usePriceListings from "../api/price-listings/usePriceLisings";
import AsyncSelect from "react-select/async";
import { AiOutlineFile } from "react-icons/ai";


type formData = {
    "filter[complaint_number]"?: string,
    "filter[item_id]"?: string,
    "filter[customer_id]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "filter[technical_sale]"?: string,
    "filter[price_classification]"?: string,
    "filter[status]"?: string,
    "filter[complaint_classification_one]"?: string,
};


export default function GrossPriceList({ setTitle }: any) {
    const { t } = useTranslation();

    // hooks
    const { priceListing } = usePriceListings();
    const { CustomStyles } = useSelectStyles();

    // state
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [selectedPriceListing, setSelectedPriceListing] = useState<any>(null);
    const [selectedPriceListValidity, setSelectedPriceListValidity] = useState<any>(null);
    const [formData, setFormData] = useState<formData>({});

    // CLIENT
    const handleSearchChange = (inputValue: any) => {
        if (inputValue) {
            setSelectedPriceListing(inputValue?.pricelist_id);
            setSelectedPriceListValidity(inputValue?.validitypricelist);
        } else {
            setSelectedPriceListing(null);
            setSelectedPriceListValidity(null);
        }
    };

    const downloadPriceListExcel = async (ev: any, type: string) => {
        ev.preventDefault();

        // avoid multiple
        if (isDownloadLoading) {
            return;
        }

        setIsDownloadLoading(true);

        const res = await client.get(`reserved-conditions/${selectedPriceListing}/gross-price-list/${type}/xlsx/download`, {
            params: {
                validitypricelist: selectedPriceListValidity,
            },
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

        setIsDownloadLoading(false);
    };

    const downloadPriceListPdf = async (ev: any, type: string) => {
        ev.preventDefault();

        // avoid multiple
        if (isDownloadLoading) {
            return;
        }

        setIsDownloadLoading(true);

        const res = await client.get(`reserved-conditions/${selectedPriceListing}/gross-price-list/${type}/pdf/download`, {
            params: {
                validitypricelist: selectedPriceListValidity,
            },
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

        setIsDownloadLoading(false);
    };

    useEffect(() => {
        setTitle("Gross price list")
    }, [])

    return <>
        {(isDownloadLoading) &&
            <Loader />}

        <div className="w-1/2 gap-1">
            <label className="text-sm">Seleziona</label>
            <AsyncSelect
                loadOptions={priceListing}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                onChange={handleSearchChange}
                styles={CustomStyles}
                placeholder={t("inserisci il codice")}
                loadingMessage={() => t("Caricamento in corso...")}
                noOptionsMessage={() => t("Nessun risultato")}
                openMenuOnClick={false}
                isClearable
                escapeClearsValue
                menuPlacement="bottom"
            />
        </div>

        {
            selectedPriceListing &&
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-5 2xl:w-1/2">
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 items-center">
                        <div className="w-14 aspect-square bg-secondary bg-opacity-5 flex justify-center items-center">
                            <AiOutlineFile className="fill-secondary w-8 h-8" />
                        </div>
                        <span className="text-lg font-semibold">Listino RB</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListExcel(ev, "rb")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListPdf(ev, "rb")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 items-center">
                        <div className="w-14 aspect-square bg-secondary bg-opacity-5 flex justify-center items-center">
                            <AiOutlineFile className="fill-secondary w-8 h-8" />
                        </div>
                        <span className="text-lg font-semibold">Listino HT</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListExcel(ev, "ht")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListPdf(ev, "ht")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border p-6 flex flex-col gap-3 max-w-1/2">
                    <div className="flex gap-6 items-center">
                        <div className="w-14 aspect-square bg-secondary bg-opacity-5 flex justify-center items-center">
                            <AiOutlineFile className="fill-secondary w-8 h-8" />
                        </div>
                        <span className="text-lg font-semibold">Listino SAFE</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListExcel(ev, "safe")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListPdf(ev, "safe")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border p-6 flex flex-col gap-3">
                    <div className="flex gap-6 items-center">
                        <div className="w-14 aspect-square bg-secondary bg-opacity-5 flex justify-center items-center">
                            <AiOutlineFile className="fill-secondary w-8 h-8" />
                        </div>
                        <span className="text-lg font-semibold">Listino ATTREZZATURA</span>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListExcel(ev, "attrezzatura")} className="w-full">
                                {t("Excel")}
                            </Button>
                            <Button icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev) => downloadPriceListPdf(ev, "attrezzatura")} className="w-full">
                                {t("PDF")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}
