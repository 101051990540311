import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import { Tooltip } from "@reach/tooltip";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import useOffersMutations from "../../api/offers/useOffersMutations";
import { useOrderMutations } from "../../api/orders/useOrderMutations";
import { Loader } from "../Loader";
import { HiOutlineDotsVertical } from "react-icons/hi";
import OrderConfirmation from "../templates/OrderConfirmation";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import DuplicationModal from "../templates/DuplicationModal";
import { Modal } from "../commom/Modal";
import { Button } from "../Button";
import { toast } from "react-toastify";

export const OffersTableActions = (props: any) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { roles, can } = useAuthContext();
    const { client_reference, rotho_notes } = useShippingContext();

    // state
    const [openDuplicateOffer, setOpenDuplicateOffer] = useState<any>(false);
    const [OpenConfirmOrder, setOpenConfirmOrder] = useState(false);
    const [open, setOpen] = useState(false);

    // hooks
    const { createOrder } = useOrderMutations();
    const { proposeOffer, duplicateOffer, rejectOffer, newOfferVersion } = useOffersMutations();

    const offer = props.row.original;

    // PROPOSE OFFER
    const handleOfferProposal = async () => {
        await proposeOffer.mutateAsync({ cart_id: offer?.id });
        navigate(`/offers/${offer?.id}/created`);
    };

    // REJECT OFFER
    const handleRejectOffer = async () => {
        await toast.promise(rejectOffer.mutateAsync({ cart_id: offer?.id }), {
            pending: t("Carico..."),
            success: t("Modifica effettuata con successo"),
            error: t("La modifica non è stata effettuata")
        });
    };

    // NEW OFFER VERSION
    const handleNewOfferVersion = async () => {
        const res = await newOfferVersion.mutateAsync({ offer_id: offer?.id });
        navigate(`/offers/${res.data.data.id}`, { replace: true });
    };

    // CREATE ORDER
    const handleCreateOrder = async () => {
        const res = await createOrder.mutateAsync({ cart_id: offer?.id, client_reference, rotho_notes });
        navigate(`/order-sent/${res?.data?.data?.order_id}`);
    };

    // GENERATE ACTION BUTTONS
    const generateButtons = () => {
        if (props.row.original.offer_expired) {
            return <>
                {
                    can(["can_make_offer"]) &&
                    !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                    <Menu>
                        {offer.offer_state !== "readonly" &&
                            <MenuButton>
                                <Tooltip label={"actions"} style={{ backgroundColor: "#F1F5F6", borderRadius: "3px" }}>
                                    <span>
                                        <HiOutlineDotsVertical className='text-lg cursor-pointer' />
                                    </span>
                                </Tooltip>
                            </MenuButton>
                        }
                        <MenuList className="offers-dropdown">
                            <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={() => setOpenDuplicateOffer(true)}>{t("Duplica offerta")}</MenuItem>
                        </MenuList>
                    </Menu>
                }
            </>;
        } else if (offer?.offer_state === "requested") {
            return <>
                {
                    can(["can_make_offer"]) &&
                    !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                    <Menu>
                        {offer.offer_state !== "readonly" &&
                            <MenuButton>
                                <Tooltip label={"actions"} style={{ backgroundColor: "#F1F5F6", borderRadius: "3px" }}>
                                    <span>
                                        <HiOutlineDotsVertical className='text-lg cursor-pointer' />
                                    </span>
                                </Tooltip>
                            </MenuButton>
                        }
                        <MenuList className="offers-dropdown">
                            <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={() => navigate(`/offers/${offer.id}`)}>{t("Modifica")}</MenuItem>
                            <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={handleOfferProposal}>{t("Crea offerta")}</MenuItem>
                        </MenuList>
                    </Menu>
                }
            </>;
        } else if (offer?.offer_state === "proposed") {
            return <>
                <Menu>
                    {offer.offer_state !== "readonly" &&
                        <MenuButton>
                            <Tooltip label={"actions"} style={{ backgroundColor: "#F1F5F6", borderRadius: "3px" }}>
                                <span>
                                    <HiOutlineDotsVertical className='text-lg cursor-pointer' />
                                </span>
                            </Tooltip>
                        </MenuButton>
                    }
                    <MenuList className="offers-dropdown">
                        {!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ?
                            (
                                <>
                                    {
                                        can(["can_make_offer"]) && <>
                                            <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={() => setOpenDuplicateOffer(true)}>{t("Duplica offerta")}</MenuItem>
                                            <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={handleNewOfferVersion}>{t("Nuova versione")}</MenuItem>
                                        </>
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        open &&
                                        <Modal title={t("Rifiuta offerta")} open={open} onClose={setOpen}>
                                            <p>{t("Sei sicuro di rifiutare l'offerta?")}</p>
                                            <div className="text-right space-x-2 mt-4">
                                                <Button variant={Button.variant.text} onClick={() => setOpen(false)}>{t("Annulla")}</Button>
                                                <Button variant={Button.variant.primary} onClick={handleRejectOffer}>{t("Procedi")}</Button>
                                            </div>
                                        </Modal>
                                    }
                                    <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={() => setOpen(true)}>{t("Rifiuta offerta")}</MenuItem>
                                </>
                            )
                        }
                        <OrderConfirmation open={OpenConfirmOrder} setOpen={setOpenConfirmOrder} handleConfirmClick={handleCreateOrder} clientReference={offer?.client_reference} />
                        {
                            can(["can_make_order"]) &&
                            offer?.customer?.type !== "DUMMY CUSTOMER" &&
                            (!offer?.customer?.legallock && !offer.customer?.invoicing?.legallock) &&
                            !offer.items?.find((item: any) => item?.priceclassification?.startsWith("S-H0-45")) &&
                            offer?.items.length > 0 &&
                            <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={() => setOpenConfirmOrder(true)}>{t("Trasforma in ordine")}</MenuItem>
                        }
                    </MenuList>
                </Menu>
            </>;
        } else if (offer?.offer_state === "rejected") {
            return <>
                {
                    can(["can_make_offer"]) &&
                    !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                    <Menu>
                        {
                            offer.offer_state !== "readonly" &&
                            <MenuButton>
                                <Tooltip label={"actions"} style={{ backgroundColor: "#F1F5F6", borderRadius: "3px" }}>
                                    <span>
                                        <HiOutlineDotsVertical className='text-lg cursor-pointer' />
                                    </span>
                                </Tooltip>
                            </MenuButton>
                        }
                        <MenuList className="offers-dropdown">
                            <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={handleNewOfferVersion}>{t("Nuova versione")}</MenuItem>
                        </MenuList>
                    </Menu>
                }
            </>;
        } else if (offer?.offer_state === "accepted") {
            return <>
                {
                    can(["can_make_offer"]) &&
                    !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) &&
                    <Menu>
                        {
                            offer.offer_state !== "readonly" &&
                            <MenuButton>
                                <Tooltip label={"actions"} style={{ backgroundColor: "#F1F5F6", borderRadius: "3px" }}>
                                    <span>
                                        <HiOutlineDotsVertical className='text-lg cursor-pointer' />
                                    </span>
                                </Tooltip>
                            </MenuButton>
                        }
                        <MenuList className="offers-dropdown">
                            <MenuItem className="offers-dropdown-items offers-dropdown-items-selected" onSelect={() => setOpenDuplicateOffer(true)}>{t("Duplica offerta")}</MenuItem>
                        </MenuList>
                    </Menu>
                }
            </>;
        } else {
            return null;
        }
    };

    return (
        <>
            {(createOrder.isLoading || newOfferVersion.isLoading || duplicateOffer.isLoading || rejectOffer.isLoading || proposeOffer.isLoading) && <Loader />}

            {/* DUPLICATION MODAL */}
            <DuplicationModal
                label={t("Duplica offerta")}
                modalState={openDuplicateOffer}
                setModalState={setOpenDuplicateOffer}
                data={offer}
                type="offer"
            />

            {generateButtons()}
        </>
    );
};
